<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div
        v-if="$store.getters.getMobileModel !== 'PDA' && showQrScaner==='block'"
        class="qrcode-wrap"
        :style="{display: showQrScaner}"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <v-row dense>
        <v-col
          :cols="$store.getters.getMobileModel !== 'PDA' ? 5 : 6"
        >
          <v-text-field
            id="input-qrkey"
            v-model="qrKey"
            label="파레트번호"
            placeholder="QR 스캔"
            clearable
            autocomplete="off"
            @keyup.enter.prevent="onEnterPcode"
          />
        </v-col>
        <v-col
          v-if="$store.getters.getMobileModel !== 'PDA'"
          class="pb-0 pt-4 pl-0"
          cols="1"
        >
          <v-icon
            @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
          >
            mdi-camera
          </v-icon>
        </v-col>
        <v-spacer />
        <v-col
          cols="5"
        >
          <v-text-field
            id="input-qty"
            v-model="takeQty"
            label="폐기수량"
            type="number"
            clearable
            autocomplete="off"
            @keyup.enter.prevent="onEnterTakeQty"
          />
        </v-col>
      </v-row>
      <div style="margin-top:10px; background-color:black;">
        <dx-data-grid
          ref="refGoodsInputGrid"
          :data-source="palletInfoList"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="gridHeight"
          :focused-row-enabled="true"
          :focused-row-key.sync="focusedRowKeyPallet"
          :auto-navigate-to-focused-row="false"
          key-expr="seqId"
          @focused-row-changed="onSelectionChangedPallet"
        >
          <DxColumn
            :allow-editing="false"
            caption="NO"
            data-field="입고키"
            sort-order="desc"
            :min-width="60"
          />
          <DxColumn
            :allow-editing="false"
            caption="품번"
            data-field="품번"
            :min-width="100"
          />
          <DxColumn
            :allow-editing="false"
            caption="잔량"
            data-field="현재수량"
            format="#,##0"
            :min-width="80"
          />
          <DxColumn
            :allow-editing="true"
            caption="유효일"
            data-field="유효일"
            data-type="date"
            format="yy/MM/dd"
            :min-width="100"
          />
          <DxColumn
            :allow-editing="false"
            caption="품명"
            data-field="품명"
            :min-width="120"
          />
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
            show-scrollbar="always"
            :use-native="true"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
      <v-row
        dense
        class="mt-2"
      >
        <v-col
          cols="12"
        >
          <v-btn
            width="100%"
            color="primary"
            @click="onClickSave"
          >
            폐기 등록
          </v-btn>
        </v-col>
      </v-row>
      <div style="margin-top:14px; background-color:black;">
        <dx-data-grid
          ref="refGoodsOutputGrid"
          :data-source="outputListInfo"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="gridHeight"
          :focused-row-enabled="false"
          @row-removing="onRowRemoving"
          @row-removed="onRowRemoved"
        >
          <DxColumnFixing :enabled="true" />
          <DxEditing
            :allow-deleting="true"
            :allow-updating="false"
            :allow-adding="false"
            :use-icons="true"
            mode="row"
          />
          <DxColumn
            caption="파레트"
            data-field="입고키"
            :min-width="60"
          />
          <DxColumn
            :visible="false"
            caption="품번"
            data-field="품번"
            :min-width="100"
          />
          <DxColumn
            caption="퍠기수량"
            data-field="수량"
            format="#,##0"
            :min-width="60"
          />
          <DxColumn
            caption="유효일"
            data-field="동종재고코드"
            :min-width="80"
          />
          <DxColumn
            width="50"
            :buttons="editButtons"
            type="buttons"
          />
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
            show-scrollbar="always"
            :use-native="true"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import DxDataGrid, {
  DxScrolling, DxColumnFixing, DxEditing,
  DxColumn, DxPaging, DxSorting
} from 'devextreme-vue/data-grid'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../BnhMesVueWin/src/share/ConstDef'
import axios from 'axios'
// import AesCrypto from '../../share/service/AesCrypto'

export default {
  name: 'EmptView',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxColumnFixing,
    DxEditing,
    QrCodeScanComponent
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      // qrcode scan
      showQrScaner: 'none',
      qrKey: null,
      palletInfoList: null,
      focusedRowKeyPallet: null,
      takeQty: 0,
      selectedRowPallet: null,
      // pickingresult grid
      outputListInfo: null,
      gridHeight: 150,
      editButtons: ['delete']
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
  },
  mounted () {
    this.initInput()
  },
  updated () {
  },
  methods: {
    initInput () {
      this.takeQty = null
      this.focusedRowKeyPallet = null
      setTimeout(() => {
        this.$Q('#input-qrkey').focus()
      }, 100)
    },
    onClickSave () {
      if (this.selectedRowPallet === null) {
        this.$snotify.info('파레트정보 그리드에서 폐기처리할 품목을 선택 하여 주세요')
        return
      }
      if (parseInt(this.takeQty) > this.selectedRowPallet.현재수량) {
        this.$snotify.info('선택된 품목 잔량 보다  폐기수량이 많습니다.')
        return
      }
      this.$snotify.confirm('폐기 처리 하시겠습니까?', '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '폐기',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              this.productDisposal()
            }
          },
          {
            text: '취소',
            action: (toast) => { this.$snotify.remove(toast.id) }
          }
        ]
      })
    },
    productDisposal () {
      const param = {
        입고키: this.qrKey,
        입출고구분: ConstDef.폐기처리,
        품번: this.selectedRowPallet.품번,
        품명: this.selectedRowPallet.품명,
        수량: this.takeQty,
        발주번호: `${AppLib.nowTimestamp()}`,
        유효일: this.selectedRowPallet.유효일,
        동종재고코드: this.selectedRowPallet.동종재고코드,
        장소위치코드: this.selectedRowPallet.장소위치코드
      }
      this.isLoading = true
      this.$_sp.runInsertAndFindSqlProc('창고수불', ['품번', '발주번호'], null, param)
        .then((data) => {
          this.isLoading = false
          param.seqId = this.$_sp.MakeModel(data)[0].seqId
          this.selectedRowPallet.현재수량 = this.selectedRowPallet.현재수량 - parseInt(this.takeQty)
          this.$refs.refGoodsInputGrid.instance.refresh()
          this.$snotify.info('저장 되었습니다.')
          this.initInput()
          this.outputListInfo.push(param)
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onEnterPcode (e) {
      if (this.qrKey === null || this.qrKey.length === 0) return
      let split = null
      split = AppLib.base64DecodeSubstring(this.qrKey)
      this.palletInfoList = null
      this.qrKey = parseInt(split)
      const disposal = this.$_sp.runNoEncodeFindSqlProc('창고수불', { 입고키: this.qrKey, 입출고구분: ConstDef.폐기처리 })
      const pInfo = this.$_sp.runNoEncodeFindProc('spFindAllReceiptByKeyAndInput', { 입고키: this.qrKey, 입출고구분: ConstDef.자재입고 })
      axios.all([disposal, pInfo])
        .then(axios.spread((...reponse) => {
          this.palletInfoList = this.$_sp.MakeModel(reponse[1])
          if (this.palletInfoList.length === 0) {
            this.$snotify.info('해당 파레트번호로 검색된 품목이 없습니다.')
            this.initInput()
          } else {
            this.outputListInfo = this.$_sp.MakeModel(reponse[0])
            this.$Q('#input-qty').focus()
          }
        }))
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onEnterTakeQty (e) {
      this.onClickSave()
    },
    onSelectionChangedPallet (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRowPallet = e.row.data
        this.takeQty = this.selectedRowPallet.현재수량
        setTimeout(() => {
          this.$Q('#input-qty').focus()
        }, 0)
      }
    },
    // 폐기처리 그리드
    onRowRemoving (row) {

    },
    onRowRemoved (row) {
      this.isLoading = true
      this.qrKey = row.data.입고키
      this.$_sp.runDeleteSqlProc('창고수불', [{ seqId: row.data.seqId }])
        .then((date) => {
          this.isLoading = false
          this.onEnterPcode(null)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">

</style>
